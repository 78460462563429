function Home() {
    return (
        <>
            <div>
                <p className="title">Kairen Vanspark</p>
            </div>
            <div>
                <img id="icon" src='/pfp.jpg' width="400" height="400" alt="icon"/>
            </div>
        </>
    )
}
export default Home;